import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {


  function sendEmail() {
    try{
      // axios.post('https://api.innoestate.fr/emails/add', {email: document.querySelector('#emailInput').value})
      axios.get(`https://api.innoestate.fr/emails/add?email=${document.querySelector('#emailInput').value}&app=innoestate`).then((res) => {
        alert('merci!');
      });
    }catch(e){
      console.error(e);
      alert(`une erreur s'est produite!`);

    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative bg-gray-900 pb-12 md:pb-24'>
          <nav className='relative'>
            <div className='container mx-auto px-4'>
              <div className='relative flex h-24 items-center'>
                <a className='inline-block' href='#'></a>
                <button className='lg:hidden navbar-burger flex items-center justify-center h-10 w-10 ml-auto border border-teal-900 bg-teal-200 bg-opacity-30 hover:bg-teal-700 rounded-lg'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3 5H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 12H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M3 19H21'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </button>
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden lg:flex items-center'>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#'
                  >
                    <div className='flex items-center'>
                      <span className='mr-3'>Accueil</span>
                    </div>
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400 mr-10'
                    href='#subscribe'
                  >
                    souscription
                  </a>
                  <a
                    className='inline-block text-white hover:text-teal-400'
                    href='/policies'
                  >
                    Politique de confidentialité
                  </a>
                </div>
                <div className='hidden lg:block ml-auto'>
                  <a
                    className='group inline-block justify-center p-1 text-center text-sm text-white font-semibold rounded-lg'
                    href='#'
                  >
                    <div className='inline-flex items-stretch h-10 p-0.5 rounded-lg bg-gradient-to-b from-gray-500 via-gray-700 to-gray-700 hover:to-gray-800'></div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
          <div className='container px-4 mx-auto'>
            <div className='relative py-16 pb-20 xs:pb-40 md:pb-72 px-2 xs:px-8 bg-gray-800 rounded-3xl overflow-hidden'>
              <div className='relative max-w-lg lg:max-w-3xl mb-14 mx-auto text-center'>
                <div className='max-w-lg md:max-w-2xl px-4 mx-auto'>
                  <h1 className='font-heading tracking-tight text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6'>
                    Innoestate Holdings
                  </h1>
                  <p className='max-w-lg mx-auto text-lg text-gray-300 mb-10'>
                    innovation dans l'immobilier
                  </p>
                </div>
              </div>
            </div>
            <img
              className='relative block mx-auto px-12 lg:px-8 -mt-20 xs:-mt-40 md:-mt-64'
              src='images/pexels-pixabay-273209-copie.jpg'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-teal-800 opacity-70' />
            <nav className='relative flex flex-col py-6 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='aurora-assets/logos/aurora-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Product
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Features
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a className='inline-block text-black' href='#'>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a className='inline-block text-black' href='#'>
                      Resources
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex items-center justify-center h-12 mb-4 px-4 text-center text-base text-gray-700 font-semibold border border-gray-200 hover:bg-gray-100 shadow-lg rounded-lg transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='flex items-center justify-center h-12 px-4 text-center text-base text-white font-semibold bg-gradient-to-b from-cyanGreen-800 to-cyan-800 rounded-lg transition duration-200'
                  href='#'
                >
                  Get Started
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section id='subscribe' className='py-12 md:py-24 bg-gray-900'>
          <div className='container px-4 mx-auto'>
            <div className='py-16 px-8 md:px-12 xl:px-18 bg-gray-800 border-2 border-gray-700 rounded-xl'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-10 lg:mb-0'>
                  <div>
                    <h3
                      className='font-heading tracking-tight text-4xl font-bold text-white mb-4'
                      contentEditable='false'
                    >
                      Restez informé
                    </h3>
                    <p
                      className='text-lg text-gray-400'
                      contentEditable='false'
                    >
                      News sur les applications développés par innoestate
                      holdings
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='lg:max-w-md lg:ml-auto'>
                    <div className='sm:flex mb-5 items-center'>
                      <input
                        id="emailInput"
                        className='py-3 px-4 h-12 w-full mb-3 sm:mb-0 sm:mr-3 text-gray-400 placeholder-gray-400 bg-gray-800 border border-gray-700 outline-none ring ring-transparent focus:ring-teal-800 rounded-lg'
                        type='email'
                        placeholder='Votre email'
                        contentEditable='false'
                      />
                      <button
                        className='sm:flex-shrink-0 group relative w-full sm:w-auto h-12 flex xs:inline-flex items-center justify-center px-4 p-px font-bold text-gray-700 bg-white rounded-lg transition-all duration-300 focus:outline-none'
                        type='submit'
                        onClick={sendEmail}
                      >
                        <div
                          className='absolute top-0 left-0 w-full h-full rounded-lg ring ring-gray-500 animate-pulse group-hover:ring-0 transition duration-300'
                          contentEditable='false'
                        />
                        <span>S'inscrire</span>
                      </button>
                    </div>
                    <span className='block text-sm text-gray-500'>
                      Pas de spam. Vous pouvez vous désinscrire à toute moment.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

