import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Policies() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='relative py-12 md:py-24'>
        <div className='container px-4 mx-auto'>
          <div className='max-w-4xl mx-auto'>
            <div className='mb-12'>
              <h4
                className='text-3xl text-gray-900 font-bold mb-6'
                contentEditable='false'
              >
                Politique de confidentialité
              </h4>
              <p
                className='text-lg text-gray-700 mb-6'
                contentEditable='false'
              />
              <div>DONNÉES PERSONNELLES</div>
              <div>
                <br />
              </div>
              <div>Définitions</div>
              <div>
                <br />
              </div>
              <div>
                L'Éditeur : La personne, physique ou morale, qui édite les
                services de communication au public en ligne.
              </div>
              <div>
                <br />
              </div>
              <div>
                Le Site : L'ensemble des sites, pages Internet et services en
                ligne proposés par l'Éditeur. L'Utilisateur : La personne
                utilisant le Site et les services.
              </div>
              <div>
                <br />
              </div>
              <div>1- Nature des données collectées</div>
              <div>
                <br />
              </div>
              <div>
                Dans le cadre de l'utilisation des Sites, l'Éditeur est
                susceptible de collecter les catégories de données suivantes
                concernant ses Utilisateurs :
              </div>
              <div>
                <br />
              </div>
              <div>Données d'état-civil, d'identité, d'identification...</div>
              <div>
                <br />
              </div>
              <div>2- Communication des données personnelles à des tiers</div>
              <div>
                <br />
              </div>
              <div>
                Pas de communication à des tiers Vos données ne font l'objet
                d'aucune communication à des tiers. Vous êtes toutefois informés
                qu'elles pourront être divulguées en application d'une loi, d'un
                règlement ou en vertu d'une décision d'une autorité
                réglementaire ou judiciaire compétente. 3- Information préalable
                pour la communication des données personnelles à des tiers en
                cas de fusion / absorption
              </div>
              <div>
                <br />
              </div>
              <div>
                Information préalable et possibilité d’opt-out avant et après la
                fusion / acquisition Dans le cas où nous prendrions part à une
                opération de fusion, d’acquisition ou à toute autre forme de
                cession d’actifs, nous nous engageons à garantir la
                confidentialité de vos données personnelles et à vous informer
                avant que celles-ci ne soient transférées ou soumises à de
                nouvelles règles de confidentialité.
              </div>
              <div>
                <br />
              </div>
              <div>4- Agrégation des données</div>
              <div>
                <br />
              </div>
              <div>
                Agrégation avec des données non personnelles Nous pouvons
                publier, divulguer et utiliser les informations agrégées
                (informations relatives à tous nos Utilisateurs ou à des groupes
                ou catégories spécifiques d'Utilisateurs que nous combinons de
                manière à ce qu'un Utilisateur individuel ne puisse plus être
                identifié ou mentionné) et les informations non personnelles à
                des fins d'analyse du secteur et du marché, de profilage
                démographique, à des fins promotionnelles et publicitaires et à
                d'autres fins commerciales.
              </div>
              <div>
                <br />
              </div>
              <div>
                Agrégation avec des données personnelles disponibles sur les
                comptes sociaux de l'Utilisateur Si vous connectez votre compte
                à un compte d’un autre service afin de faire des envois croisés,
                ledit service pourra nous communiquer vos informations de
                profil, de connexion, ainsi que toute autre information dont
                vous avez autorisé la divulgation. Nous pouvons agréger les
                informations relatives à tous nos autres Utilisateurs, groupes,
                comptes, aux données personnelles disponibles sur l’Utilisateur.
              </div>
              <div>
                <br />
              </div>
              <div>5- Collecte des données d'identité</div>
              <div>
                <br />
              </div>
              <div>Consultation libre</div>
              <div>
                <br />
              </div>
              <div>
                La consultation du Site ne nécessite pas d'inscription ni
                d'identification préalable. Elle peut
              </div>
              <div>
                <br />
              </div>
              <div>
                s'effectuer sans que vous ne communiquiez de données nominatives
                vous concernant (nom,
              </div>
              <div>
                <br />
              </div>
              <div>
                prénom, adresse, etc). Nous ne procédons à aucun enregistrement
                de données nominatives
              </div>
              <div>
                <br />
              </div>
              <div>pour la simple consultation du Site.</div>
              <div>
                <br />
              </div>
              <div>
                6- Collecte des données d'identification Utilisation de
                l'identifiant de l’utilisateur uniquement pour l’accès aux
                services Nous utilisons vos identifiants électroniques seulement
                pour et pendant l'exécution du contrat.
              </div>
              <div>
                <br />
              </div>
              <div>7- Collecte des données du terminal</div>
              <div>
                <br />
              </div>
              <div>
                Collecte des données de profilage et des données techniques à
                des fins de fourniture du service Certaines des données
                techniques de votre appareil sont collectées automatiquement par
                le Site. Ces informations incluent notamment votre adresse IP,
                fournisseur d'accès à Internet, configuration matérielle,
                configuration logicielle, type et langue du navigateur... La
                collecte de ces données est nécessaire à la fourniture des
                services.
              </div>
              <div>
                <br />
              </div>
              <div>
                Collecte des données techniques à des fins publicitaires,
                commerciales et statistiques Les données techniques de votre
                appareil sont automatiquement collectées et enregistrées par le
                Site, à des fins publicitaires, commerciales et statistiques.
                Ces informations nous aident à personnaliser et à améliorer
                continuellement votre expérience sur notre Site. Nous ne
                collectons ni ne conservons aucune donnée nominative (nom,
                prénom, adresse...) éventuellement attachée à une donnée
                technique. Les données collectées sont susceptibles d’être
                revendues à des tiers.
              </div>
              <div>
                <br />
              </div>
              <div>8- Cookies</div>
              <div>
                <br />
              </div>
              <div>
                Durée de conservation des cookies Conformément aux
                recommandations de la CNIL, la durée maximale de conservation
                des cookies est de 13 mois au maximum après leur premier dépôt
                dans le terminal de l'Utilisateur, tout comme la durée de la
                validité du consentement de l’Utilisateur à l’utilisation de ces
                cookies. La durée de vie des cookies n’est pas prolongée à
                chaque visite. Le consentement de l’Utilisateur devra donc être
                renouvelé à l'issue de ce délai.
              </div>
              <div>
                <br />
              </div>
              <div>
                Finalité cookies Les cookies peuvent être utilisés pour des fins
                statistiques notamment pour optimiser les services rendus à
                l'Utilisateur, à partir du traitement des informations
                concernant la fréquence d'accès, la personnalisation des pages
                ainsi que les opérations réalisées et les informations
                consultées.
              </div>
              <div>
                <br />
              </div>
              <div>
                Vous êtes informé que l'Éditeur est susceptible de déposer des
                cookies sur votre terminal. Le cookie enregistre des
                informations relatives à la navigation sur le service (les pages
                que vous avez consultées, la date et l'heure de la
                consultation...) que nous pourrons lire lors de vos visites
                ultérieures.
              </div>
              <div>
                <br />
              </div>
              <div>
                Opt-in pour le dépôt de cookies Nous n'utilisons pas de cookies.
                Si nous devions en utiliser à l’avenir, vous en seriez informé
                préalablement et auriez la possibilité de désactiver ces
                cookies.
              </div>
              <div>
                <br />
              </div>
              <div>
                9 - Conservation des données techniques Durée de conservation
                des données techniques Les données techniques sont conservées
                pour la durée strictement nécessaire à la réalisation des
                finalités visées ci-avant.
              </div>
              <div>
                <br />
              </div>
              <div>
                10- Délai de conservation des données personnelles et
                d'anonymisation
              </div>
              <div>
                <br />
              </div>
              <div>
                Conservation des données pendant la durée de la relation
                contractuelle Conformément à l'article 6-5° de la loi n°78-17 du
                6 janvier 1978 relative à l'informatique, aux fichiers et aux
                libertés, les données à caractère personnel faisant l'objet d'un
                traitement ne sont pas conservées au-delà du temps nécessaire à
                l'exécution des obligations définies lors de la conclusion du
                contrat ou de la durée prédéfinie de la relation contractuelle.
              </div>
              <div>
                <br />
              </div>
              <div>
                Conservation des données anonymisées au delà de la relation
                contractuelle / après la suppression du compte Nous conservons
                les données personnelles pour la durée strictement nécessaire à
                la réalisation des finalités décrites dans les présentes
                Politiques de confidentialité. Au-delà de cette durée, elles
                seront anonymisées et conservées à des fins exclusivement
                statistiques et ne donneront lieu à aucune exploitation, de
                quelque nature que ce soit.
              </div>
              <div>
                <br />
              </div>
              <div>
                Suppression des données après suppression du compte Des moyens
                de purge de données sont mis en place afin d'en prévoir la
                suppression effective dès lors que la durée de conservation ou
                d'archivage nécessaire à l'accomplissement des finalités
                déterminées ou imposées est atteinte. Conformément à la loi
                n°78-17 du 6 janvier 1978 relative à l'informatique, aux
                fichiers et aux libertés, vous disposez par ailleurs d'un droit
                de suppression sur vos données que vous pouvez exercer à tout
                moment en prenant contact avec l'Éditeur.
              </div>
              <div>
                <br />
              </div>
              <div>
                Suppression des données après 3 ans d'inactivité Pour des
                raisons de sécurité, si vous ne vous êtes pas authentifié sur le
                Site pendant une période de trois ans, vous recevrez un e-mail
                vous invitant à vous connecter dans les plus brefs délais, sans
                quoi vos données seront supprimées de nos bases de données.
              </div>
              <div>
                <br />
              </div>
              <div>11- Suppression du compte</div>
              <div>
                <br />
              </div>
              <div>
                Suppression du compte à la demande L'Utilisateur a la
                possibilité de supprimer son Compte à tout moment, par simple
                demande à l'Éditeur OU par le menu de suppression de Compte
                présent dans les paramètres du Compte le cas échéant.
              </div>
              <div>
                <br />
              </div>
              <div>
                Suppression du compte en cas de violation de la Politique de
                Confidentialité En cas de violation d'une ou de plusieurs
                dispositions de la Politique de Confidentialité ou de tout autre
                document incorporé aux présentes par référence, l'Éditeur se
                réserve le droit de mettre fin ou restreindre sans aucun
                avertissement préalable et à sa seule discrétion, votre usage et
                accès aux services, à votre compte et à tous les Sites.
              </div>
              <div>
                <br />
              </div>
              <div>
                12- Indications en cas de faille de sécurité décelée par
                l'Éditeur
              </div>
              <div>
                <br />
              </div>
              <div>
                Information de l'Utilisateur en cas de faille de sécurité Nous
                nous engageons à mettre en oeuvre toutes les mesures techniques
                et organisationnelles appropriées afin de garantir un niveau de
                sécurité adapté au regard des risques d'accès accidentels, non
                autorisés ou illégaux, de divulgation, d'altération, de perte ou
                encore de destruction des données personnelles vous concernant.
                Dans l'éventualité où nous prendrions connaissance d'un accès
                illégal aux données personnelles vous concernant stockées sur
                nos serveurs ou ceux de nos prestataires, ou d'un accès non
                autorisé ayant pour conséquence la réalisation des risques
                identifiés ci-dessus, nous nous engageons à :
              </div>
              <div>
                <br />
              </div>
              <div>
                Vous notifier l'incident dans les plus brefs délais ; Examiner
                les causes de l'incident et vous en informer ; Prendre les
                mesures nécessaires dans la limite du raisonnable afin
                d'amoindrir les effets négatifs et préjudices pouvant résulter
                dudit incident.
              </div>
              <div>
                <br />
              </div>
              <div>
                Limitation de la responsabilité En aucun cas les engagements
                définis au point ci-dessus relatifs à la notification en cas de
                faille de sécurité ne peuvent être assimilés à une quelconque
                reconnaissance de faute ou de responsabilité quant à la
                survenance de l'incident en question.
              </div>
              <div>
                <br />
              </div>
              <div>13- Transfert des données personnelles à l'étranger</div>
              <div>
                <br />
              </div>
              <div>
                Pas de transfert en dehors de l'Union européenne L'Éditeur
                s'engage à ne pas transférer les données personnelles de ses
                Utilisateurs en dehors de l'Union européenne.
              </div>
              <div>
                <br />
              </div>
              <div>
                https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-mondehttps://www.cnil.fr/fr/la-protectiondes-donnees-dans-le-monde
              </div>
              <div>
                <br />
              </div>
              <div>14- Modification de la politique de confidentialité</div>
              <div>
                <br />
              </div>
              <div>
                En cas de modification de la présente Politique de
                Confidentialité, engagement de ne pas baisser le niveau de
                confidentialité de manière substantielle sans l'information
                préalable des personnes concernées Nous nous engageons à vous
                informer en cas de modification substantielle de la présente
                Politique de Confidentialité, et à ne pas baisser le niveau de
                confidentialité de vos données de manière substantielle sans
                vous en informer et obtenir votre consentement.
              </div>
              <div>
                <br />
              </div>
              <div>15- Droit applicable et modalités de recours</div>
              <div>
                <br />
              </div>
              <div>
                Application du droit français (législation CNIL) et compétence
                des tribunaux La présente Politique de Confidentialité et votre
                utilisation du Site sont régies et interprétées conformément aux
                lois de France, et notamment à la Loi n° 78-17 du 6 janvier 1978
                relative à l'informatique, aux fichiers et aux libertés. Le
                choix de la loi applicable ne porte pas atteinte à vos droits en
                tant que consommateur conformément à la loi applicable de votre
                lieu de résidence. Si vous êtes un consommateur, vous et nous
                acceptons de se soumettre à la compétence nonexclusive des
                juridictions françaises, ce qui signifie que vous pouvez engager
                une action relative à la présente Politique de Confidentialité
                en France ou dans le pays de l'UE dans lequel vous vivez. Si
                vous êtes un professionnel, toutes les actions à notre encontre
                doivent être engagées devant une juridiction en France.
              </div>
              <div>
                <br />
              </div>
              <div>
                En cas de litige, les parties chercheront une solution amiable
                avant toute action judiciaire. En cas d'échec de ces tentatives,
                toutes contestations à la validité, l'interprétation et / ou
                l'exécution de la Politique de Confidentialité devront être
                portées même en cas de pluralité des défendeurs ou d'appel en
                garantie, devant les tribunaux français. 16- Portabilité des
                données
              </div>
              <div>
                <br />
              </div>
              <div>
                Portabilité des données L'Éditeur s'engage à vous offrir la
                possibilité de vous faire restituer l'ensemble des données vous
                concernant sur simple demande. L'Utilisateur se voit ainsi
                garantir une meilleure maîtrise de ses données, et garde la
                possibilité de les réutiliser. Ces données devront être fournies
                dans un format ouvert et aisément réutilisable.
              </div>
              <p />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

